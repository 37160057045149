interface AkamaiImageLoaderProps {
	src: string
	width: number
}

export function akamaiImageLoader({
	src,
	width,
}: AkamaiImageLoaderProps): string {
	const baseUrl = process.env.NEXT_PUBLIC_SITE_BASE_URL
	if (!baseUrl) {
		throw new Error('NEXT_PUBLIC_SITE_BASE_URL is not defined')
	}
	const url = new URL(src, baseUrl)
	// if resource has ts parameter, keep it
	const previousAssetsTs = url.searchParams.get('ts')
	const searchParams = new URLSearchParams({
		imwidth: width.toString(),
		imdensity: '1',
		ts: previousAssetsTs ?? '1', // Provisional fix to prevent akamai excessive images compression
	})
	url.search = searchParams.toString()

	return url.toString()
}
