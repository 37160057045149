const TEST_IDS = {
	PARAGRAPH: 'mng-paragraph',
	LINK: 'mng-link',
	BOLD: 'mng-bold',
	ITALIC: 'mng-italic',
	TITLE: 'mng-title',
	SUBTITLE: 'mng-subtitle',
	BUTTON: 'mng-button',
	UNDERLINE: 'mng-underline',
	TABLE: 'mng-table',
	LIST: 'mng-list',
	CONTAINER: 'mng-div',
	SECTION: 'mng-section',
	IMAGE: 'mng-img',
}

export default TEST_IDS
