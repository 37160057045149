'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useEffect } from 'react'
import { StatusCode } from 'types/statusCodes'

export const AnalyticEventNotFound = () => {
	useEffect(() => {
		sendGenericEvent(`Error ${StatusCode.NOT_FOUND}`)
	}, [])

	return null
}
